import logger from '@knauf-group/ct-shared-nextjs/logger'

import { InvertedRouting, RootPath, RootPathISR, RootPathSSR } from '@/constants/routing'
import type {
  CategoryPathCalculationParams,
  HomePathParams,
  ProductPathCalculationParams,
} from '@/types/routing'

import { isNotEmpty } from './isEmpty'

export const getHomePath = (params: HomePathParams) => `/${params.locale}`

export const getCategoryPagePath = <IsSSR extends boolean = false>(
  params: CategoryPathCalculationParams,
  isSSR: IsSSR = false as IsSSR,
) => {
  const { locale, categorySlug, search } = params

  const localeLowerCase = locale?.toLowerCase()

  let errorMessage: string | undefined
  if (!locale) {
    errorMessage = `Missing locale info in getCategoryPagePath. Params: ${JSON.stringify(
      params,
    )}`
  } else if (!InvertedRouting[localeLowerCase]) {
    errorMessage = `getCategoryPagePath: '${localeLowerCase}' does not exist in 'InvertedRouting'`
  } else if (!InvertedRouting[localeLowerCase].category) {
    errorMessage = `getCategoryPagePath: 'category' key does not exist in 'InvertedRouting[${localeLowerCase}]': ${JSON.stringify(
      InvertedRouting[localeLowerCase],
    )}`
  }

  if (errorMessage) {
    logger.error(errorMessage)
    throw new Error(errorMessage)
  }

  const rootPath = (isSSR ? RootPathSSR : RootPathISR) as IsSSR extends true
    ? typeof RootPathSSR
    : typeof RootPathISR

  const { category } = InvertedRouting[localeLowerCase]

  const normalizedSearch = isNotEmpty(search) ? search : ''

  if (isNotEmpty(categorySlug))
    return `/${rootPath}/${category}/${categorySlug}${normalizedSearch}` as const

  return `/${rootPath}/${category}${normalizedSearch}` as const
}

export const getProductDetailsPath = (params: ProductPathCalculationParams) => {
  const { locale, productSlug } = params

  const localeLowerCase = locale?.toLowerCase()

  let errorMessage: string | undefined
  if (!locale) {
    errorMessage = `Missing locale info in getProductDetailsPath. Params: ${JSON.stringify(
      params,
    )}`
  } else if (!InvertedRouting[localeLowerCase]) {
    errorMessage = `getProductDetailsPath: '${localeLowerCase}' does not exist in 'InvertedRouting'`
  } else if (!InvertedRouting[localeLowerCase].product) {
    errorMessage = `getProductDetailsPath: 'product' key does not exist in 'InvertedRouting[${localeLowerCase}]': ${JSON.stringify(
      InvertedRouting[localeLowerCase],
    )}`
  }

  if (errorMessage) {
    logger.error(errorMessage)
    throw new Error(errorMessage)
  }

  const rootPath = RootPath as typeof RootPath
  const { product } = InvertedRouting[localeLowerCase]

  return `/${rootPath}/${product}/${productSlug}` as const
}
